import React, { useState, useEffect } from "react"
import client from '../api/contentful'

import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import CtaRightRailDetail from "../components/CtaRightRailDetail"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from '../components/linkOptions'

export const query = graphql`
query tutorialQuery($slug: String!) {
    contentfulTutorialPage(slug: { eq: $slug }) {
        slug
    }
}
`

const Tutorial = ({data}) => {
    const doc = data.contentfulTutorialPage;
    const[content, setContent] = useState();
    const[imageSizes, setImageSizes] = useState([]);

    useEffect(() => {
        client.getEntries({
            content_type: 'contentPage',
            'fields.slug[in]': doc.slug,
        })
        .then(entry => setContent(entry.items[0]))
        .catch(err => console.log(err));
    }, [doc.slug])

    useEffect(() => {
        if(content && content.fields.componentReference) {
            content.fields.componentReference.fields.imageSizes.forEach(size =>
                client.getEntries({
                    content_type: 'imageSizes',
                    'sys.id[in]': size.sys.id,
                })
                .then(entry => setImageSizes([...imageSizes, entry.items[0].fields]))
                // .then(entry => console.log(entry.items[0].fields))
                .catch(err => console.log(err))
            )
        }

    }, [content])


    const formatTutorialStartTime = (startTime) => {
        if(content.fields.tutorialVideoStartTime) {
            let newStart = startTime.split(":");
            let minute = +newStart[0]*60;
            let seconds = +newStart[1];
            return(minute+seconds);
        } else {
            return null
        }
    }

    return (
        <Layout>
            {content &&
                <SEO title={content.fields.title} />
            }
        {content &&
            <Container>
            <section className="page-content col">
                <div className="row">
                    <div className="col-12">
                        <section className="hero">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 offset-lg-1">
                                        <h1 className="hero-title">{content.fields.title}</h1>
                                        {content.fields.description && <div className="hero-description">{content.fields.description}</div>}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-lg-9 richtext">
                        {content.fields.pageContent && documentToReactComponents(content.fields.pageContent, options)}

                        {content.fields.componentReference && <section className="related-links mt-5">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col" style={{padding: '0'}}>
                                        <div className="related-links-wrapper">
                                            <h2 className="related-links-title">More about the component</h2>
                                            <div className="uta-btn-group">
                                                    <Link role="button" className="uta-btn uta-btn-inverse-ghost-secondary" to={`/components/${content.fields.componentReference.fields.slug}`}>{content.fields.componentReference.fields.name}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>}
                    </div>

                   <div className="col-lg-3">
                        {content.fields.tutorialVideoId && 
                            <CtaRightRailDetail
                                headline="Video Tutorial"
                            >
                                <div className="pt-3 pb-4" style={{maxWidth: '640px'}}><div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden'}}><iframe width="640" height="360" title={content.fields.title}src={`https://web.microsoftstream.com/embed/video/${content.fields.tutorialVideoId}?autoplay=false&amp;showinfo=true;${content.fields.tutorialVideoStartTime ? `&st=${formatTutorialStartTime(content.fields.tutorialVideoStartTime)}` : ''}`} allowFullScreen style={{border:'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '100%', maxWidth: '100%'}}></iframe></div></div>
                            </CtaRightRailDetail>
                        }

                        
                        {imageSizes && imageSizes.length > 0 && 
                            <CtaRightRailDetail
                                headline="Image Sizes"
                            >
                                {imageSizes.map(size => (
                                        <p className="pb-3" key={`${size.width}x${size.height}`}><strong>{size.width} x {size.height}</strong> <span className="text-muted">pixels</span></p>
                                    )
                                )}
                            </CtaRightRailDetail>
                        }
                    </div>
                </div>
            </section>
            </Container>
            
}
        </Layout>
    )
}

export default Tutorial
